import { useLocalStorage } from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'
import { defineStore } from 'pinia'

import { destroyEcho } from '@/plugins/echo.js'

const cookies = useCookies()

const host =
  window.location.hostname === 'localhost'
    ? 'localhost'
    : '.' + window.location.hostname.split('.').slice(-2).join('.')

const defaultOptions = window.location.protocol.startsWith('https')
  ? {
      path: '/',
      domain: window.location.hostname,
      secure: true,
      sameSite: 'none',
    }
  : {
      path: '/',
      domain: host,
    }

const AUTH_COOKIE_NAME = 'nsat'
const REFERRAL_COOKIE_NAME = 'nsr'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null,
    token: cookies.get(AUTH_COOKIE_NAME),
    returnUrl: false,
    loading: {
      user: false,
      login: false,
      socials: false,
      register: false,
      restore: false,
      code: false,
    },
  }),

  getters: {
    hasEsia() {
      return this.user?.permissions?.roles?.includes('esia') ?? false
    },
    hasEsiaTrusted() {
      return this.user?.has_trusted_esia ?? false
    },
    hasEsiaAge() {
      return this.user?.age > 16 ?? false
    },
  },

  actions: {
    async sendCode(email) {
      this.loading.code = true
      return await this.http('/auth/emails/verify', {
        method: 'POST',
        body: {
          email,
        },
      }).finally(() => (this.loading.code = false))
    },
    async fetchUser() {
      this.loading.user = true
      return new Promise(async (resolve) => {
        const storage = useLocalStorage('logged-in-as-user', null)
        if (this.token || storage.value) {
          this.http('/auth/show')
            .then((user) => {
              this.user = user
              if (this.returnUrl !== false) {
                this.router.push(this.returnUrl || '/')
                this.returnUrl = null
              }
              resolve()
            })
            .catch(async () => {
              this.token = null
              this.user = null
              cookies.remove(AUTH_COOKIE_NAME, defaultOptions)
              destroyEcho()
              resolve()
            })
            .finally(() => (this.loading.user = false))
        } else {
          resolve()
        }
      })
    },

    async logout() {
      if (this.token) {
        await this.http('/auth/logout', {
          method: 'POST',
        })
      }

      this.user = null
      this.token = null
      cookies.remove(AUTH_COOKIE_NAME, defaultOptions)
      destroyEcho()

      if (this.router.currentRoute.meta.auth === true) {
        this.router.push('/')
      }
    },

    /**
     * @param {Object: {token:string}} token
     * @param {Boolean} remember
     */
    async loginWithToken(token, remember = true) {
      const date = new Date()
      date.setFullYear(date.getFullYear() + 1)

      cookies.remove(AUTH_COOKIE_NAME, defaultOptions)
      cookies.remove(REFERRAL_COOKIE_NAME)
      cookies.set(AUTH_COOKIE_NAME, token, {
        ...defaultOptions,
        ...(remember
          ? {
              expires: date,
            }
          : {
              maxAge: undefined,
            }),
      })

      this.token = token
      await this.fetchUser()
    },

    /**
     * @param {Object: {login:string, password:string, remember:boolean}} user
     */
    async login(user) {
      this.loading.login = true

      await this.http('/auth/login', {
        method: 'POST',
        body: user,
      })
        .then(async ({ token }) => this.loginWithToken(token, user.remember))
        .finally(() => {
          this.loading.login = false
          cookies.remove(REFERRAL_COOKIE_NAME)
        })
    },

    /**
     * @param {Object: {
     *   name: string,
     *   surname: string,
     *   patronymic: string,
     *   phone: string,
     *   email: string,
     *   gender: string,
     *   password: string
     * }} body
     * @return {Promise<void>}
     */
    async register(body) {
      this.loading.register = true
      return await this.http('/auth/register', {
        method: 'POST',
        body,
      }).finally(() => {
        this.loading.register = false
        cookies.remove(REFERRAL_COOKIE_NAME)
      })
    },

    async restore(body) {
      this.loading.restore = true
      return await this.http('/auth/reset', {
        method: 'POST',
        body,
      }).finally(() => {
        this.loading.restore = false
      })
    },

    async loginViaSocial(body) {
      this.loading.login = true

      return await this.http('/auth/socials/register', {
        method: 'POST',
        body,
      }).finally(() => (this.loading.login = false))
    },

    async getSocials() {
      this.loading.socials = true

      return await this.http('/auth/socials', {
        method: 'GET',
      }).finally(() => (this.loading.socials = false))
    },

    async linkSocial(body) {
      this.loading.socials = true

      return await this.http('/auth/socials/link', {
        method: 'POST',
        body,
      }).finally(() => (this.loading.socials = false))
    },
  },
})
