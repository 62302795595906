import Echo from 'laravel-echo'
import Pusher from 'pusher-js'

import { useAuthStore } from '@/store/auth'

window.Pusher = Pusher

let echo = null

function initEcho() {
  const auth = useAuthStore()

  echo = new Echo({
    ...window.WS_SETTINGS,
    auth: {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    },
  })
}

export function destroyEcho() {
  echo = null
}

function useEcho() {
  if (!echo) initEcho()
  return echo
}

export default useEcho
